import {
    Alert,
    Button,
    ButtonDropdown,
    ColumnLayout,
    Container,
    ContentLayout,
    CopyToClipboard,
    Header,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    Table,
} from "@cloudscape-design/components";
import React, { FunctionComponent, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import {
    useArchiveFeatureFlagMutation,
    useGetFeatureFlagQuery,
    useRestoreFeatureFlagMutation,
    useUpdateFeatureFlagMutation,
} from "../../../api/effApi";
import AllocationsBar from "../../common/AllocationsBar";
import ApiErrorAlert from "../../common/ApiErrorAlert";
import ConfirmationModal from "../../common/ConfirmationModal";
import Debug from "../../common/Debug";
import Link from "../../common/Link";
import ValueWithLabel from "../../common/ValueWithLabel";
import orderStates from "../FeatureFlagEnvDetailPage/orderStates";
import ModifyPermissionsModal from "./ModifyPermissionsModal";

enum Actions {
    MANAGE_PERMISSIONS = "manage-permissions",
    ARCHIVE = "archive",
    RESTORE = "restore",
}

const FeatureFlagDetailPage: FunctionComponent = () => {
    const params = useParams();
    const id = params.id!;
    const user = useAuth().user!.profile.sub;
    const { data, isLoading, refetch, isFetching, error: getFeatureFlagError } = useGetFeatureFlagQuery(id);
    const [updateFeatureFlag, { error: updatePermissionsError }] = useUpdateFeatureFlagMutation();
    const [archiveFeatureFlag, { error: archiveFeatureFlagError }] = useArchiveFeatureFlagMutation();
    const [restoreFeatureFlag, { error: restoreFeatureFlagError }] = useRestoreFeatureFlagMutation();
    const [isModifyPermissionsModalOpen, setIsModifyPermissionsModalOpen] = useState(false);
    const [isArchiveFeatureFlagConfirmationOpen, setIsArchiveFeatureFlagConfirmationOpen] = useState(false);
    const [isRestoreFeatureFlagConfirmationOpen, setIsRestoreFeatureFlagConfirmationOpen] = useState(false);

    if (isLoading || !data) {
        return <Spinner />;
    }

    if (getFeatureFlagError) {
        return <ApiErrorAlert error={getFeatureFlagError} />;
    }

    if (!data.featureFlag) {
        return <Alert type="error">Feature flag ({id}) not found</Alert>;
    }

    const handleActionClick = (actionId: string) => {
        switch (actionId) {
            case Actions.MANAGE_PERMISSIONS:
                setIsModifyPermissionsModalOpen(true);
                break;
            case Actions.ARCHIVE:
                setIsArchiveFeatureFlagConfirmationOpen(true);
                break;
            case Actions.RESTORE:
                setIsRestoreFeatureFlagConfirmationOpen(true);
                break;
            default:
                throw new Error(`Unexpected action: ${actionId}`);
        }
    };

    const featureFlag = data.featureFlag;

    const hasOwnerPermissions = featureFlag.owners.includes(user);
    const isArchived = !!featureFlag.archivedTime;

    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    {isArchived && "[ARCHIVED] "}
                    {featureFlag.displayName}
                </Header>
            }
        >
            <SpaceBetween size="l">
                <ApiErrorAlert errors={[updatePermissionsError, archiveFeatureFlagError, restoreFeatureFlagError]} />

                <Container
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => refetch()} loading={isFetching} iconName="refresh" />
                                    {hasOwnerPermissions && (
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    id: Actions.MANAGE_PERMISSIONS,
                                                    text: "Manage Permissions",
                                                    description:
                                                        "Change the owners and operators of this feature flag.",
                                                    disabled: isArchived,
                                                    disabledReason: "Feature flag is archived",
                                                },
                                                ...(!isArchived
                                                    ? [
                                                          {
                                                              id: Actions.ARCHIVE,
                                                              text: "Archive Feature Flag",
                                                              description:
                                                                  "Hides the feature flag from most views and prevents future modifications. Can be restored later, if necessary.",
                                                              disabled: isArchived,
                                                              disabledReason: "Feature flag is archived",
                                                          },
                                                      ]
                                                    : []),
                                                ...(isArchived
                                                    ? [
                                                          {
                                                              id: Actions.RESTORE,
                                                              text: "Restore Feature Flag",
                                                              description:
                                                                  "Unarchives the feature flag to allow further usage.",
                                                          },
                                                      ]
                                                    : []),
                                            ]}
                                            onItemClick={e => {
                                                handleActionClick(e.detail.id);
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    )}
                                </SpaceBetween>
                            }
                            variant="h2"
                        >
                            General Information
                        </Header>
                    }
                >
                    <ColumnLayout columns={4}>
                        <ValueWithLabel label="ID">
                            <CopyToClipboard
                                textToCopy={featureFlag.id}
                                copySuccessText={`Copied "${featureFlag.id}"`}
                                copyErrorText="Failed"
                                variant="inline"
                            />
                        </ValueWithLabel>
                        <ValueWithLabel label="Description">{featureFlag.description}</ValueWithLabel>
                        <ValueWithLabel label="Owners">{featureFlag.owners.join(", ")}</ValueWithLabel>
                        <ValueWithLabel label="Operators">
                            {featureFlag.operators.length > 0 && featureFlag.operators.join(", ")}
                            {featureFlag.operators.length === 0 && (
                                <StatusIndicator type="stopped">None</StatusIndicator>
                            )}
                        </ValueWithLabel>
                        <ValueWithLabel label="Created By">{featureFlag.createdBy}</ValueWithLabel>
                        <ValueWithLabel label="Created">
                            {new Date(featureFlag.createdTime).toLocaleString()}
                        </ValueWithLabel>
                        {isArchived && (
                            <ValueWithLabel label="Archived">
                                {new Date(featureFlag.archivedTime).toLocaleString()}
                            </ValueWithLabel>
                        )}
                    </ColumnLayout>
                </Container>

                <Table
                    header={<Header variant="h2">Environments</Header>}
                    items={featureFlag.envIds.map(envId => ({
                        ...featureFlag.environments[envId],
                        id: envId,
                    }))}
                    columnDefinitions={[
                        {
                            header: "ID",
                            cell: env => <Link to={`/feature-flags/${id}/env/${env.id}`}>{env.id}</Link>,
                        },
                        {
                            header: "Name",
                            cell: env => env.displayName,
                        },
                        {
                            header: "Dialup Percentage",
                            cell: env => (
                                <AllocationsBar allocations={orderStates(env.states).currentState?.allocations} />
                            ),
                        },
                        {
                            header: "Overrides Count",
                            cell: env => env.overridesCount,
                        },
                    ]}
                    trackBy="id"
                    variant="container"
                />

                <Debug value={data} />
            </SpaceBetween>

            {isModifyPermissionsModalOpen && (
                <ModifyPermissionsModal
                    currentPermissions={{ owners: featureFlag.owners, operators: featureFlag.operators }}
                    onClose={() => setIsModifyPermissionsModalOpen(false)}
                    onSave={async permissions => {
                        await updateFeatureFlag({ ffId: id, ...permissions });
                        setIsModifyPermissionsModalOpen(false);
                    }}
                />
            )}

            {isArchiveFeatureFlagConfirmationOpen && (
                <ConfirmationModal
                    headerText={`Confirm Archiving "${featureFlag.displayName}"`}
                    onClose={() => setIsArchiveFeatureFlagConfirmationOpen(false)}
                    onConfirm={async () => {
                        await archiveFeatureFlag(id);
                        setIsArchiveFeatureFlagConfirmationOpen(false);
                    }}
                >
                    <>
                        <p>Are you sure you want to archive this feature flag?</p>
                        <p>
                            This will hide the feature flag from the list of active feature flags and prevent further
                            modifications.
                        </p>
                        <p>
                            The feature flag can be restored later, if necessary. Archived feature flags are not
                            automatically deleted.
                        </p>
                    </>
                </ConfirmationModal>
            )}

            {isRestoreFeatureFlagConfirmationOpen && (
                <ConfirmationModal
                    headerText={`Confirm Restoring "${featureFlag.displayName}"`}
                    onClose={() => setIsRestoreFeatureFlagConfirmationOpen(false)}
                    onConfirm={async () => {
                        await restoreFeatureFlag(id);
                        setIsRestoreFeatureFlagConfirmationOpen(false);
                    }}
                >
                    <>
                        <p>Are you sure you want to restore this feature flag?</p>
                        <p>This will unarchive the feature flag and allow it to be used like normal again.</p>
                    </>
                </ConfirmationModal>
            )}
        </ContentLayout>
    );
};

export default FeatureFlagDetailPage;
